header {
    height: 175px;
    @media (max-width: 768px) and (orientation: landscape) {
        height: 250px;
    }
    @media (min-width: 768px) {
        height: auto;
    }

    img.background {
        display: inline;
        vertical-align: top;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
    }

    img.logo {
        position: absolute;
        left: 21%;
        top: 9%;
        width: 52%;

        @media (min-width: 768px) {
            left: 31%;
            top: 10%;
            width: 38%;
        }

    }

    .forAccessibility {
        clip: rect(1px 1px 1px 1px); /* IE 6/7 */
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}