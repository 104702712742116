.hambMenu {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 40px 20px;

    .hambLines {
        display: block;
        height: 2px;
        position: relative;
        width: 24px;
    }

    .hambLines::before,
    .hambLines::after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all .2s ease-out;
        width: 100%;
    }

    .hambLines::before {
        top: 5px;
    }

    .hambLines::after {
        top: -5px;
    }

    .closeBtn {
        display: block;
        height: 2px;
        position: relative;
        width: 24px;
    }

    .closeBtn::before,
    .closeBtn::after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all .2s ease-out;
        width: 100%;
        top: 0;
    }

    .closeBtn::before {
        transform: rotate(-45deg);
    }

    .closeBtn::after {
        transform: rotate(45deg);
    }

    @media (min-width: 768px) {
        display: none;
    }
}

@keyframes fadeInKeyframe {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation: fadeInKeyframe 0.5s ease-in;
    -moz-animation: fadeInKeyframe 0.5s ease-in;
    -o-animation: fadeInKeyframe 0.5s ease-in;
    animation: fadeInKeyframe 0.5s ease-in;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: none;

    &.active {
        display: block;
    }

    ul {
        margin: 50% 0 0;
        padding: 0;

        li {
            display: block;
            padding-bottom: 2rem;
            text-align: center;
            ul.secondary {
                padding: 0;
                margin: 0;
                li {
                    padding-bottom: 0;
                  a {
                    font-size: 1.5rem;
                    @media (min-width: 768px) {
                        font-size: 1rem;
                    }
                  }
                }
              }
            a {
                font-size: 1.2rem; 
                text-decoration: none;
                text-align: center;
            }
        }
    }

    @media (min-width: 768px) {
        margin: 0;
        padding: 0.7rem 0;
        position: static;
        display: block;

        ul {
            list-style-type: none;
            padding: 0;
            text-align: center;
            margin: 0 auto;

            li {
                display: inline-block;
                padding: 0 0.75rem;
                text-align: center;
                ul.secondary {
                    display: none;
                    &.desktop.active {
                        display: block;
                        position: absolute;
                        bottom: -24px;
                        left: 0;
                        width: 100%;
                        &.sticky-nav {
                            position: fixed;
                            top: 0;
                            left: 0;
                            height: 24px;
                            z-index: 2;
                        }
                    }
                  }
                a {
                    font-size: 1.2rem;
                    font-weight: bold;
                    text-decoration: none;
                }
            }
        }
    }
}