@font-face {
  font-family: 'AvenirNextBold';
  src: url('/fonts/avenir-next-bold.eot');
  src: url('/fonts/avenir-next-bold.eot') format('embedded-opentype'),
       url('/fonts/avenir-next-bold.woff2') format('woff2'),
       url('/fonts/avenir-next-bold.woff') format('woff'),
       url('/fonts/avenir-next-bold.ttf') format('truetype'),
       url('/fonts/avenir-next-bold.svg') format('svg');
  font-style: normal;
  font-weight: bolder;
}