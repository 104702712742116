@import './fonts.scss';  
  
  header {
    position: relative;
  }

  // .navSocialContainer {
  //   position: relative;
  // }

  .content {
    background: #fff;
    padding: 4% 5% 5em;
    height: auto;
    // &.work {
    //   padding-top: 8%;
    // }
    hr {
      margin: 1rem 0;
      border-top: 1px #c9c9c9 solid;
      height: 1px;
    }

    a {
      color: #4B5F35;
      text-decoration: none;

      &:hover {
        color: #afcc5b;
      }

      &::before {
        display: block;
        content: " ";
        margin-top: -30px;
        height: 30px;
        visibility: hidden;
        pointer-events: none; 
        @media (min-width: 768px) {
          margin-top: -40px;
          height: 40px;
        }
      }
    }

    .item {
      margin: 2rem 0;
    }

    img {
      border: 1px #ccc solid;
      width: 100%;
      margin-bottom: 1rem;
    }

    h1 {
      font-size: 1.6rem;
      font-weight: bold;
    }

    h1,
    h2,
    h3,
    h4 {
      color: #333;
    }

    h1 span {
      font-size: 1.2rem;
      display: block;
      color: #575A4E;
    }

    h2 {
      font-size: 1.4rem;
      font-weight: bold;
      margin: 2rem 0 1rem;

    }

    h3 {
      margin-top: 2rem;
      font-size: 1rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
    }

    a.btn {
      color: #fff;
      background-color: #333;
      text-decoration: none;
      border: 0 none;
      margin: 1rem 1rem 1rem 0rem;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;

      &:hover {
        background-color: #afcc5b;
        color: #222;
      }
    }

    .sample {
      margin: 1rem 0 2rem;
    }

    pre {
      font-size: 0.7rem;
    }
    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 768px) {
      img {
        width: 100%;
      }
    }
  }

  .socialMediaLinks {
    position: absolute;
    top: 0;
    left: 0;
    list-style-type: none;
    margin: 0 1rem;

    ul {
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }

  .hambMenu {
    background-color: #222;

    .hambLines {
        background: white;
    }

    .hambLines::before,
    .hambLines::after {
        background: white;
    }

    .closeBtn::before,
    .closeBtn::after {
        background: white;
    }
}

nav {
  background-color: #222;

  ul {
      li {

          a {
              color: #fff;
              &:hover,
              &:active {
                  color: #71A022;
              }
          }
      }
  }
  .secondary {
    display: none;
  }
  @media (min-width: 768px) {

      ul {


          li {

              ul.secondary {
                  &.desktop.active {
                      background: #525A3C;
                      li {
                          a {
                              color: #fff;
                              &:hover {
                                  color: #afcc5b;
                              }
                          }
                      }
                  }
                }
              a {
                  &.selected {
                      color: #afcc5b
                  }
              }
          }
      }
  }
}

.intro {
  .inner {
    margin: 0 auto;
    vertical-align: middle;
  }
  background: #f2f2f2;
  color: #222;
  padding: 1rem;
  font-family: 'AvenirNextBold', sans-serif;
  font-size: 1.6rem;
  p {
    margin: 0;
    @media (min-width: 768px) {
      text-align: center;   
    }
  } 
}

.intro-mobile {
  display: block;
  @media (min-width: 768px) {
    display: none;   
  }
}

.intro-desktop {
  display: none;
  @media (min-width: 768px) {
    display: block;   
  }
}

.testimonial {
  padding: 1rem;
  font-style: italic;
  font-weight: 500;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
  .reviewer {
    margin-top: 1rem;
    font-size: 0.9rem;
    font-weight: normal;
  }
}

.code {
  img {
    @media (min-width: 768px) {
      width: 32%;   
    } 
  }
}